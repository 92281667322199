/* Dialog Box Styling */
.dialog {
    width: 600px;
    max-width: 90%; /* Ensures responsiveness */
    border-radius: 16px !important;
    padding: 24px;
  }
  
  /* Title Styling */
  .dialog-title {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: left;
    margin-bottom: 10px;
  }
  
  /* Content Styling */
  .dialog-content {
    display: flex;
    flex-direction: column;
    gap: 32px !important;
    padding-bottom: 20px;
  }
  
  /* Content Text Styling */
  .dialog-content-text {
    font-size: 1rem;
    color: #666666;
  }
  
  /* Input Styling */
  .dialog-input {
    width: 100%;
    margin-bottom: 15px;
  }
  
  /* Actions Styling */
  .dialog-actions {
    display: flex !important;
    justify-content: flex-start !important;
    margin-left: 20px !important;
    gap: 23px !important;
  }
  
  /* Buttons Styling */
  .dialog-button-cancel {
    padding: 10px 20px !important;
    text-transform: none !important;
    border: 1px solid #0E2C53 !important;
    width: 160px !important;
    height: 40px !important;
    border-radius: 8px !important;
    color: #061425 !important;
  }
  
  .dialog-button-submit {
    background-color: #29B475 !important;
    color: #061425 !important;
    transition: background-color 0.3s ease !important;
    border: 1px solid #0E2C53 !important;
    width: 160px !important;
    height: 40px !important;
    border-radius: 8px !important;
    text-transform: none !important;
  }
  .dialog-button-delete {
    background-color: #b43229 !important;
    color: #061425 !important;
    transition: background-color 0.3s ease !important;
    border: 1px solid #0E2C53 !important;
    width: 160px !important;
    height: 40px !important;
    border-radius: 8px !important;
    text-transform: none !important;
    }
    
  .dialog-button-submit:hover {
    background-color: #155a9c;
  }
  